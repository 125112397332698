export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'home-featured home-content',

  FACET_PANEL_CLASS: 'page-sidebar',
  MAIN_CONTENT_CLASS: 'page-content',
  FILTERS_TEXT: 'Part Category / Filters',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'form-button',
  SEARCH_BOX_PLACEHOLDER: 'Search the store',

  FILTER_SEARCH_BOX_FORM_CLASS: 'form',
  FILTER_SEARCH_BOX_INPUT_CLASS: 'form-input',
  FILTER_SEARCH_BOX_BUTTON_CLASS: 'form-input',

  GARAGE_SIZE: '<span class="garage-size countPill  countPill--positive" key="garage-size">{{size}}</span>',

  VERIFY_FITMENT_SPECIFY:
    'We need more information to ensure compability.<br/> Please specify {{fieldsToSpecify.join(", ")}} for your {{vehicleString}}',
};
