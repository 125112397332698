const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = ['Submodel', 'DriveType'];

const categorySelectionPageUrl = '/parts/';
const brandSelectionPageUrl = '/brands/';

function InitFunc() {
  const convermaxPages = ['/search/', categorySelectionPageUrl, brandSelectionPageUrl];

  if (convermaxPages.includes(window.location.pathname)) {
    window.document.querySelector('.page-content.page-content--centered').classList = '';
  }
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    forceVehicleSelection: true,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'main.pages-category .page-content, main.pages-brand .page-content',
      template: 'MainContent',
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: 'main.pages-category .page-sidebar, main.pages-brand .page-sidebar',
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'FilterSearchBox',
    },
    {
      name: 'SearchBox',
      location: { replace: '.header-search form.form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        insertAfter: '.header .mobileMenu-toggle',
        class: 'cm_search-box-root__dialog_open-button_container',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm',
        id: 'cm_HomeVehicleWidget',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 900,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 550,
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser-item--cart',
        wrapper: 'li',
        class: 'navUser-item cm_garage_container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_parts',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'VerifyFitment',
      location: {
        insertAfter: 'main.pages-product .productView-details.product-data',
        class: 'productView-details',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
